import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import { D1, ButtonText, Technical } from '../theme/typography';

// Asset Imports
import StarsSVG from '../images/icons/stars.inline.svg';

// Styled Components
const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  gap: 64px;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Quote = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--primary-text);

  text-wrap: balance;

  align-self: flex-start;
`;

const Stars = styled(StarsSVG)`
  width: 84px;
  height: 16px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: flex-end;

  @media (min-width: 1024px) {
    min-width: 200px;
  }
`;

const Author = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Headshot = styled(GatsbyImage)`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 16px;
`;

const Client = styled.div`
  display: flex;
  flex-direction: column;
`;
const ClientName = styled(ButtonText)`
  display: flex;
  flex-direction: column;
  color: var(--secondary-text);

  @media (min-width: 500px) {
    display: unset;
  }
`;

const JobTitle = styled(Technical)`
  text-transform: none;
  color: var(--tertiary-text);
`;

const ClientLogo = styled.div`
  fill: var(--logo-fill, var(--blue3));
  fill: var(--blue4);
  height: 56px;
  aspect-ratio: 16/9;
  align-self: flex-end;

  display: flex;
  justify-content: flex-end;

  > * {
    max-height: 100%;
  }
`;

const LargeTestimonial = ({ data }) => {
  return (
    <Testimonial>
      <Quote>
        <Stars />
        <D1 as='h2'>{`“${data.quoteHighlight}”`}</D1>
      </Quote>
      <Info>
        <Author>
          {/* {data.image && (
            <Headshot
              image={data.image.asset.gatsbyImageData}
              alt='Client Headshot'
            />
          )} */}
          <Client>
            <ClientName>{data.author}</ClientName>
            <JobTitle as='span'>{data.jobTitle}</JobTitle>
          </Client>
        </Author>
        {/* <ClientLogo /> */}
        {data.client.svgLogo && (
          <ClientLogo
            dangerouslySetInnerHTML={{ __html: data.client.svgLogo }}
          />
        )}
      </Info>
    </Testimonial>
  );
};

export default LargeTestimonial;
