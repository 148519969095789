import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

//Component Imports
import { TwoCols, TwoCol, Section } from '../components/page-elements';
import SEO from '../components/seo';
import Container from '../components/container';
import PrimaryHeaderBlock from '../components/primaryHeaderBlock';
import PhotoCollage from '../components/photoCollage';
import LargeTestimonial from '../components/largeTestimonial';
import FourPointBlock from '../components/fourPointBlock';
import BottomCut from '../components/bottomCut';
import PageTextBlock from '../components/pageTextBlock';
import CenteredTextBlock from '../components/centeredTextBlock';
import VideoGrid from '../components/videoGrid';
import Spacer from '../components/spacer';
import CTABlock from '../components/ctaBlock';
import AnimatedStripe from '../components/animatedStripe';
import ScrollDownPrompt from '../components/scrollDownPrompt';
import ScrollToTopButton from '../components/scrollToTopButton';

//Asset Imports
import Stripes04SVG from '../images/stripes/stripes04.inline.svg';
import LogoMarquee from '../components/logoMarquee';

//Function Imports
import shuffleArray from '../helpers/shuffleArray';

//Styled Components
const HeroVideoContainer = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: unset;
  }

  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);

  height: 100%;
  overflow: hidden;
`;

const HeroVideo = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const HeroBottomCut = styled(BottomCut)`
  @media (min-width: 1024px) {
    display: none;
  }
`;

const PortfolioStripes = styled(AnimatedStripe)`
  position: absolute;
  top: -25%;
  right: -65%;
  width: 150%;
  height: 130%;
  pointer-events: none;
  z-index: 1;

  @media (min-width: 600px) and (max-width: 1120px) {
    top: -35%;
    width: 100%;
  }
`;

const IndustryPage = ({ data }) => {
  const {
    sanityIndustryPage,
    allSanityClient,
    allSanityVideo,
    allSanityProductPage,
  } = data;

  return (
    <>
      <ScrollToTopButton />
      <ScrollDownPrompt />
      <SEO title={`Video For ${sanityIndustryPage.industryTag.label}`} />
      <Helmet>
        <body className='light-nav' />
      </Helmet>
      <Section dark style={{ marginTop: 'var(--site-header-height)' }}>
        <Container>
          <TwoCols>
            <TwoCol>
              <PrimaryHeaderBlock data={sanityIndustryPage.hero} />
            </TwoCol>
            <HeroVideoContainer>
              <HeroVideo
                autoPlay
                muted
                loop
                playsInline
                preload='metadata'
                poster={sanityIndustryPage.heroVideo.poster.asset.url}
              >
                <source
                  src={sanityIndustryPage.heroVideo.webm.asset.url}
                  type='video/webm'
                />
                <source
                  src={sanityIndustryPage.heroVideo.mp4.asset.url}
                  type='video/mp4'
                />
              </HeroVideo>
            </HeroVideoContainer>
          </TwoCols>
        </Container>
        <HeroBottomCut light />
      </Section>
      <Section light cuts={'bottomCut'} style={{ overflowX: 'clip' }}>
        <CenteredTextBlock data={sanityIndustryPage.logoBlock} />
        <Spacer size={48} />
        {allSanityClient.nodes.length > 10 ? (
          <LogoMarquee clients={allSanityClient.nodes} twoRows />
        ) : (
          <LogoMarquee clients={allSanityClient.nodes} />
        )}
        <BottomCut />
      </Section>
      <Section cuts={'topCut'}>
        <Container>
          <TwoCols reverse>
            <TwoCol centerVertically>
              <PageTextBlock data={sanityIndustryPage.marketingBlock} />
            </TwoCol>
            <TwoCol>
              <PhotoCollage
                photos={sanityIndustryPage.midPagePhotos}
                videoLoop={sanityIndustryPage.collageVideoLoop}
                layout={1}
                threshold={0.6}
              />
            </TwoCol>
          </TwoCols>
          <Spacer size={128} />
          <LargeTestimonial data={sanityIndustryPage.testimonial} />
        </Container>
      </Section>
      <Section dark cuts={'bottomCut'}>
        <FourPointBlock data={sanityIndustryPage.suggestedProducts} />
        <BottomCut />
      </Section>
      <Section cuts={'topAndBottomCut'} style={{ overflowX: 'clip' }}>
        <Container wide>
          <CenteredTextBlock data={sanityIndustryPage.featuredWorkHeading} />
          <Spacer size={64} />
          <VideoGrid
            videos={shuffleArray(allSanityVideo.nodes)}
            productRoutes={allSanityProductPage.nodes}
            style={{ position: 'relative', zIndex: 2 }}
          />
          <Spacer size={48} />
          <PortfolioStripes>
            <Stripes04SVG />
          </PortfolioStripes>
        </Container>
        <BottomCut light />
      </Section>
      <Section light cuts={'topAndBottomCut'}>
        <Container>
          <CTABlock data={sanityIndustryPage.ctaBlock} />
        </Container>
        <BottomCut dark />
      </Section>
    </>
  );
};

// GraphQL Query
export const relevantClientsQuery = graphql`
  query ($industryLabel: String) {
    sanityIndustryPage(industryTag: { label: { eq: $industryLabel } }) {
      industryTag {
        label
        slug {
          current
        }
      }
      hero {
        ...SanityPrimaryHeaderBlockFragment
      }
      heroVideo {
        ...SanityVideoLoopFragment
      }
      logoBlock {
        ...SanityCenteredTextBlockFragment
      }
      midPagePhotos {
        ...SanityPhotoCollageFragment
      }
      collageVideoLoop {
        ...SanityVideoLoopFragment
      }
      marketingBlock {
        ...SanityPageTextBlockFragment
      }
      testimonial {
        quoteHighlight
        author
        jobTitle
        client {
          svgLogo
          company
        }
      }
      suggestedProducts {
        preHeader
        title
        miniPoint1 {
          ...SanityMiniPointFragment
        }
        miniPoint2 {
          ...SanityMiniPointFragment
        }
        miniPoint3 {
          ...SanityMiniPointFragment
        }
        miniPoint4 {
          ...SanityMiniPointFragment
        }
        cta
        ctaData {
          ...SanityButtonFragment
        }
      }
      featuredWorkHeading {
        ...SanityCenteredTextBlockFragment
      }
      ctaBlock {
        ...SanityCTABlockFragment
      }
    }
    allSanityClient(
      filter: {
        industryTags: { elemMatch: { label: { eq: $industryLabel } } }
        rubberduckClient: { eq: true }
      }
    ) {
      nodes {
        company
        svgLogo
        industryTags {
          label
        }
      }
    }
    allSanityVideo(
      filter: {
        client: {
          industryTags: { elemMatch: { label: { eq: $industryLabel } } }
        }
        rubberduckProduction: { eq: true }
      }
    ) {
      nodes {
        ...SanityVideoFragment
        relatedVideos {
          ...SanityVideoFragment
        }
        productTags {
          slug {
            current
          }
          icon {
            name
          }
        }
        client {
          industryTags {
            slug {
              current
            }
            svgLogo
            label
          }
        }
      }
    }
    allSanityProductPage {
      nodes {
        productTag {
          slug {
            current
          }
        }
      }
    }
  }
`;

export default IndustryPage;
