import React from 'react';
import styled, { keyframes } from 'styled-components';
import Spacer from './spacer';

import shuffleArray from '../helpers/shuffleArray';

const translateLeft = keyframes`
 0%{
  transform: translateX(0);
 }
 100%{
  transform: translateX(-100%);
 }
`;

const translateRight = keyframes`
 0%{
  transform: translateX(0);
 }
 100%{
  transform: translateX(100%);
 }
`;

const MarqueeRow = styled.div`
  --gap: 24px;
  --padding: ${({ reverse }) =>
    reverse ? '0 0 0 var(--gap)' : '0 var(--gap) 0 0'};
  --animation-name: ${({ reverse }) =>
    reverse ? translateRight : translateLeft};
  --animation-play-state: running;

  display: flex;
  flex-direction: row;
  justify-content: ${({ reverse }) => (reverse ? 'flex-end' : '')};

  @media (pointer: fine) {
    &:hover {
      --animation-play-state: paused;
    }
  }

  @media (prefers-reduced-motion) {
    --animation-play-state: paused;
  }
`;

const Marquee = styled.div`
  width: min-content;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: var(--gap);
  padding: var(--padding); //? To create a gap between neighbouring marquees */

  animation-name: var(--animation-name);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: ${({ $numClients }) => `${$numClients * 10}s`};
  animation-direction: var(--animation-direction);
  animation-play-state: var(--animation-play-state);
`;

const LogoTile = styled.div`
  display: grid;
  place-items: center;
  background: var(--blue1);
  border-radius: 8px;
  width: clamp(125px, 4vw + 100px, 160px);
  aspect-ratio: 16 / 9;
`;

const ClientLogo = styled.svg`
  padding: 16px 24px;

  max-width: 100%;
  max-height: 100%;
  fill: var(--blue6);
`;

const LogoMarquee = ({ clients, twoRows }) => {
  let clients1;
  let clients2;

  if (twoRows) {
    clients1 = shuffleArray(clients.slice(0, clients.length / 2));
    clients2 = shuffleArray(clients.slice(clients.length / 2, clients.length));
  } else {
    clients1 = shuffleArray(clients);
  }

  return (
    <>
      <MarqueeRow>
        <Marquee $numClients={clients1.length}>
          {clients1.map(
            (client) =>
              client.svgLogo && (
                <LogoTile key={`${client.company}-1`}>
                  <ClientLogo
                    dangerouslySetInnerHTML={{ __html: client.svgLogo }}
                  />
                </LogoTile>
              ),
          )}
        </Marquee>
        <Marquee $numClients={clients1.length}>
          {clients1.map(
            (client) =>
              client.svgLogo && (
                <LogoTile key={`${client.company}-2`}>
                  <ClientLogo
                    dangerouslySetInnerHTML={{ __html: client.svgLogo }}
                  />
                </LogoTile>
              ),
          )}
        </Marquee>
        {clients1.length < 15 && (
          <Marquee $numClients={clients1.length}>
            {clients1.map(
              (client) =>
                client.svgLogo && (
                  <LogoTile key={`${client.company}-3`}>
                    <ClientLogo
                      dangerouslySetInnerHTML={{ __html: client.svgLogo }}
                    />
                  </LogoTile>
                ),
            )}
          </Marquee>
        )}
      </MarqueeRow>
      {twoRows && (
        <>
          <Spacer size={24} />
          <MarqueeRow reverse>
            {/* Using clients1.length here to ensure speed of multiple rows is always exactly the same */}
            <Marquee $numClients={clients1.length}>
              {clients2.map(
                (client) =>
                  client.svgLogo && (
                    <LogoTile key={`${client.company}-1`}>
                      <ClientLogo
                        dangerouslySetInnerHTML={{ __html: client.svgLogo }}
                      />
                    </LogoTile>
                  ),
              )}
            </Marquee>
            <Marquee $numClients={clients1.length}>
              {clients2.map(
                (client) =>
                  client.svgLogo && (
                    <LogoTile key={`${client.company}-2`}>
                      <ClientLogo
                        dangerouslySetInnerHTML={{ __html: client.svgLogo }}
                      />
                    </LogoTile>
                  ),
              )}
            </Marquee>
            {clients2.length < 15 && (
              <Marquee $numClients={clients1.length}>
                {clients2.map(
                  (client) =>
                    client.svgLogo && (
                      <LogoTile key={`${client.company}-3`}>
                        <ClientLogo
                          dangerouslySetInnerHTML={{ __html: client.svgLogo }}
                        />
                      </LogoTile>
                    ),
                )}
              </Marquee>
            )}
          </MarqueeRow>
        </>
      )}
    </>
  );
};

export default LogoMarquee;
